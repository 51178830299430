import { Alert, Button, Form, Input, InputNumber, Select, Slider } from 'antd'
import React from 'react'
import { FieldType } from '../CreateTask/CreateTaskComponent'
import { SliderMarks } from 'antd/es/slider'

//transalate
import { useTranslation, Trans } from 'react-i18next';

interface Props{
    onFinishMaster: (values: any) => void
    initialValues: FieldType
    errorMessages: string
    blockElements?: boolean

  }

const CreateTask = ({onFinishMaster, initialValues, errorMessages, blockElements}:Props) => {
    const { t } = useTranslation();

    const onFinish = (values: any) => {
        onFinishMaster(values)
      };
      
    const onFinishFailed = (errorInfo: any) => {
      };

      const marks: SliderMarks = {
        [-40]: '-40°C',
        [-20]: '-20°C',
        0: '0°C',
        20: '20°C',
        37: '37°C',
        50: '50°C',
      };

  return (
    <Form name="form_item_path" 
    layout="vertical" 
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    initialValues={initialValues}
    
    >
    <div className='CreateClient-Form'>
      {/* <Form.Item  style={{width:"100%"}} label="Ya tengo una franquicia creada" valuePropName="checked">
        <Switch checked={checked} onChange={(event)=>{setChecked(event)}} />
      </Form.Item> */}

        <Form.Item<FieldType>
        label={t('basico.nombre')}
        name="name"
        style={{width:"45%"}}
        rules={[{ required: true, message: t('basico.errorNombre') }]}
        >
        <Input placeholder={t('activo.placeHolderNombreTarea')}  disabled={blockElements}/>
        </Form.Item>    

        <Form.Item<FieldType>
        label={t('basico.descripcion')}
        name="descripcion"
        style={{width:"45%"}}
      

        rules={[{ required: true, message: t('basico.descripcionError') }]}
        >
        <Input placeholder={t('activo.placeHolderDescripcionTarea')} disabled={blockElements} />
        </Form.Item>  

        <Form.Item<FieldType>
          name="start"
          label={t('activo.modoInicio')}
          rules={[
          {
          required: true,
          whitespace: true,
          message: t('activo.errorStart'),
          },
          ]}
        style={{width:"100%"}}

          >
            <Select placeholder={t('activo.placeHolderInicio')} style={{width:"100%"}}>
              <Select.Option value="boton">{t('activo.opcionBoton')}</Select.Option>
              <Select.Option value="auto">{t('activo.opcionAuto')}</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item<FieldType>
        label={t('activo.intervaloMinutos')}
        name="interval_minutes"
        style={{width:"45%"}}
        rules={[

        ]}
        >
            <InputNumber placeholder={t('basico.minutos')} disabled={blockElements} style={{width:"100%"}}  max={100000}  />
        </Form.Item>

        <Form.Item<FieldType>
        label={t('activo.intervaloSeconds')}
        name="interval_seconds"
        style={{width:"45%"}}
        rules={[
          ({ getFieldValue }) => ({
            validator: (_, value) => {
              const minutesValue = getFieldValue('interval_minutes');
              console.log("error ", minutesValue)
              if (minutesValue === 1 && value >= 0) {
                return Promise.resolve();
              } 
              if ((minutesValue === 0 || minutesValue === null || minutesValue === undefined)&& value >= 15) {
                return Promise.resolve();
              }
              return Promise.reject(t('sensor.mensaje3'));
              
            },
          }),
        ]}
        >
            <InputNumber placeholder={t('basico.segundos')} disabled={blockElements} style={{width:"100%"}}  max={59} min={0}  />
        </Form.Item>

        <Form.Item<FieldType>
        label={t('activo.minutosTemperaturaAlta')}
        name="high_duration_minutes"
        style={{width:"45%"}}
        rules={[{ required: true, message: t('activo.errorMinutos') }]}
        >
            <InputNumber placeholder={t('basico.minutos')} disabled={blockElements} style={{width:"100%"}}  max={100000}   />
        </Form.Item>

        <Form.Item<FieldType>
        label={t('activo.minutosTemperaturaBaja')}
        name="low_duration_minutes"
        style={{width:"45%"}}
        rules={[{ required: true, message: t('activo.errorMinutos') }]}
        >
            <InputNumber placeholder={t('basico.minutos')} disabled={blockElements} style={{width:"100%"}}  max={100000}  />
        </Form.Item>



        {/* <Form.Item<FieldType>
        label="Termperatura Alta"
        name="alarm_high_temp"
        style={{width:"45%"}}
        rules={[{ required: true, message: 'Debe indicar la temperatura alta' }]}
        >
            <InputNumber style={{width:"100%"}} step="0.1" max={100000}  />
        </Form.Item>

        <Form.Item<FieldType>
        label="Termperatura baja"
        name="alarm_low_temp"
        style={{width:"45%"}}
        rules={[{ required: true, message: 'Debe indicar la temperatura baja' }]}
        >
            <InputNumber style={{width:"100%"}} step="0.1" max={100000}  />
        </Form.Item> */}

        <Form.Item<FieldType>
        label={t('activo.tareaRangoTemperatura')}
        name="temp"
        style={{width:"100%"}}
        rules={[{ required: true, message: t('activo.rangoError') }]}
        className='sliderForm'
        hasFeedback
        >
            <Slider disabled={blockElements} range min={-40} max={50} marks={marks}  />
        </Form.Item>

        {/* <Form.Item<FieldType>
        label="Dirección"
        name="direccion"
        style={{width:"100%"}}
        rules={[{ required: true, message: 'Debe escribir dirección' }]}
        >
            <TextArea rows={4} />
        </Form.Item> */}

    {errorMessages!==""&&
    <Alert
      style={{width:"100%", marginBottom:"10px"}}
      message="Error"
      description={errorMessages}
      type="error"
      closable
    />}




 
    </div>

      <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px"}}>
        <Button type="primary" htmlType="submit">
          {t('basico.siguiente')}
        </Button>
      </div>

    </Form>
  )
}

export default CreateTask