import React, { useContext, useEffect } from 'react'
import { Route } from '../Models/Route';
import { supplierRoutes, organizationRoutes, resellerRoutes, siteRoutes, nonRoutes } from '../Routes/Routes';
import { PromiseSesionContext } from '../Machines/SesionMachine';
import { useNavigate } from 'react-router-dom';
import { User } from '../Models/User';

import jwt from 'jwt-decode' 


interface Props {
    children: React.ReactNode
}

const RolGuard = ({children}:Props) => {
    const {sesion, sendSesion } = useContext(PromiseSesionContext);
    const navigate = useNavigate();

    const [routes, setRoutes] = React.useState<Route[]>(nonRoutes);
    const [validateAuth, setValidateAuth] = React.useState<boolean>(false);
    const [validateRoutes, setValidateRoutes] = React.useState<boolean>(false);
    const [show, setShow] = React.useState<boolean>(false);

    



    useEffect(() => {
        switch (sesion.context.rol) {
            case "Proveedor":
                setRoutes(supplierRoutes)
                break;
            case "Reseller":
                setRoutes(resellerRoutes)
                break;
            case "Organizacion":
                setRoutes(organizationRoutes)
                break;
            case "Sucursal":
                setRoutes(siteRoutes)
                break;
            default:
                setRoutes(nonRoutes)
                break;
        }
        if(validateAuth){
            setValidateRoutes(true)
        }
      
    }, [sesion.context.rol, validateAuth, sesion.value]);

    const validateRoute = ():boolean =>{
        let route =null
        routes.forEach((r:Route)=>{
            if(r.regex){
                if(r.regex.test(window.location.pathname)){
                    route = r
                }
            }
            else{
                if(r.path===window.location.pathname){
                    route = r
                }
            }
        })
        if(route){
            setShow(true)
            return true
        }
        setShow(false)
        return false
    }

    const validateRedirect= ()=>{
        if(!validateRoute()){
            setShow(true)
            navigate("/Login");
        }

    }

    useEffect(() => {
        if(validateAuth && validateRoutes){

            validateRedirect()
        }
      }, [validateAuth, validateRoutes]);

    useEffect(() => {
        try{
            sendSesion("VERIFYYING");
            if (localStorage.getItem("token")) {
              // sendSesion("AUTHORIZED");
              const user:User = jwt(localStorage.getItem("token") || "")
              sendSesion({type: "AUTHORIZED", data:{user: user, rol: user?.tipo}});
            }
            else{
              sendSesion("UNAUTHORIZED");
            //   navigate("/Login");
            }
            setValidateAuth(true)
        }
        catch(err){
            sendSesion("UNAUTHORIZED");
            navigate("/Login");
        }
      }, []);

    // const renderRoutes = () => {

    //     let route =null
    //     // let route =routes.find((route:Route)=>{return route.path===window.location.pathname})

    //     routes.forEach((r:Route)=>{
    //         if(r.regex){
    //             if(r.regex.test(window.location.pathname)){
    //                 route = r
    //             }
    //         }
    //         else{
    //             if(r.path===window.location.pathname){
    //                 route = r
    //             }
    //         }
    //     })

    //     if(route){
    //         return children
    //     }

    //     else {
    //         return <></>
    //     }
    // }

  return (
    <>
        {/* {renderRoutes()} */}

        {
            show ?
            children :
            <></>
        }
    </>
  )
}

export default RolGuard