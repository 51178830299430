 import { InboxOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Switch, Upload } from 'antd';
import React, { useContext, useState } from 'react'
import { PromiseSesionContext } from '../../Machines/SesionMachine';

//transalate
import { useTranslation, Trans } from 'react-i18next';

interface Props{
    onFinishMaster: (file: File) => void
    write?:boolean
    onWrite?: (sensors: any) => void
  }
  
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };

const CreateSensorFile = ({onFinishMaster, write, onWrite}:Props) => {
    const {sendError} = useContext(PromiseSesionContext);
    const [file, setFile] = useState<any>(null)
    const [check, setCheck] = useState<boolean>(true)

    const { t } = useTranslation();
    
    const onFinish = (values: any) => {
        onFinishMaster(values.dragger[0].originFileObj as File)
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
          setFile(e.target.files[0]);
        }
      };

    const normFile = (e: any) => {
        try{


        if(e.file.type === "application/json"){
            if (Array.isArray(e)) {
                return e;
              }
              return e?.fileList;
        }
        else{
            sendError({type: "ERROR", data:{message:t('basico.errorArchivoJson')}})
            return []
            
        }
        
        }
        catch(e){
            sendError({type: "ERROR", data:{message:t('basico.errorArchivo')}})
            return []

        }
      };

    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
      };

      const onFinishWrite = (values: any) => {
        onWrite!(values)
      };

  return (
    <div>
        {write && 
        <Form.Item label={t('basico.archivo')} >
          <Switch checked={check} onChange={()=>{setCheck(!check)}} />
        </Form.Item>}
        {check && <Form
            className='CreateSensorFile-Form'
            name="validate_other"
            {...formItemLayout}
            onFinish={onFinish}
        >
            <div className='CreateSensorFile-Dragger'>
                <Form.Item >
                <Form.Item rules={[{ required: true, message: t('basico.errorFormArchivo') }]} name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                    <Upload.Dragger style={{width:"400px"}} name="file" beforeUpload={()=>{return false}}>
                    <p className="ant-upload-drag-icon" >
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{t('basico.mensajeArchivo1')}</p>
                    <p className="ant-upload-hint">{t('basico.mensajeArchivo2')}</p>
                    </Upload.Dragger>
                </Form.Item>
                </Form.Item>
            </div>

            {/* <input type='file' onChange={handleFileChange} ></input> */}


            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px"}}>
                <Button type="primary" htmlType="submit">
                    {t('basico.subir')}
                </Button>
            </div>
      </Form>}


      {!check &&

<Form
name="dynamic_form_item"
{...formItemLayoutWithOutLabel}
onFinish={onFinishWrite}
style={{ maxWidth: 600 }}
>
<Form.List
  name="sensores"
  rules={[
    {
      validator: async (_, names) => {
        if (!names || names.length < 1) {
          return Promise.reject(new Error(t('basico.errorFormArchivo')));
        }
      },
    },
  ]}
>
  {(fields, { add, remove }, { errors }) => (
    <>
      {fields.map((field, index) => (
        <Form.Item
          {...( formItemLayoutWithOutLabel)}
          label={''}
          required={false}
          key={field.key}

        >
          <Form.Item
            {...field}
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                required: true,
                whitespace: true,
                message: t('sensor.errorSerial'),
              },
            ]}
            noStyle
          >
            <Input placeholder={t('sensor.serialSensor')} style={{ width: '80%' }} />
          </Form.Item>
          {fields.length > 1 ? (
            <MinusCircleOutlined
                style={{marginLeft:"5px"}}
              className="dynamic-delete-button"
              onClick={() => remove(field.name)}
            />
          ) : null}
        </Form.Item>
      ))}
      <Form.Item>
        <Button
          type="dashed"
          onClick={() => add()}
          style={{ width: '80%' }}
          icon={<PlusOutlined />}
        >
          {t('basico.agregar')}
        </Button>
        <Form.ErrorList errors={errors} />
      </Form.Item>
    </>
  )}
  </Form.List>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px"}}>
            <Button type="primary" htmlType="submit">
                {t('basico.enviar')}
            </Button>
        </div>
  </Form>

      }


    </div>
  )
}

export default CreateSensorFile