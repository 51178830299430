import { Modal } from 'antd'
import React from 'react'
import AlertAsset from './AlertAsset'

interface Props{
    id:number
    open:boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ModalAlertAsset = ({id, open, setOpen}:Props) => {
  return (
    <Modal open={open} >
        <AlertAsset assetId={id} />
    </Modal>
  )
}

export default ModalAlertAsset