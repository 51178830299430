import axios, { AxiosError, AxiosResponse } from "axios"

const baseUrl = process.env.REACT_APP_URI
const config = {
  headers: {Authorization: `Bearer ${localStorage.getItem("token")}`}
}

const getConfig = () => {
  return {
    headers: {Authorization: `Bearer ${localStorage.getItem("token") || ""}`}
  }}

const getConfigBlob = () => {
  return {
    headers: {"Content-Type": "application/pdf",Authorization: `Bearer ${localStorage.getItem("token") || ""}`, responseType:'blob'}
  }}

const getConfigFormData = () => {
  return {

    headers: {"Content-Type": "multipart/form-data", Authorization: `Bearer ${localStorage.getItem("token") || ""}`}
  }}

export const getApi = async<T>(uri:string ): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await axios.get(baseUrl + uri,getConfig());
    return response.data;
  } catch (error:any) {

    if(error?.response?.data?.message.includes("expired token")){
      localStorage.removeItem("token")
      window.location.href="/"

    }

    throw error;
  }
}

export const getApiBlob = async<T>(uri:string ): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await axios.get(baseUrl + uri,getConfigBlob());
    return response.data;
  } catch (error:any) {
    if(error?.response?.data?.message.includes("expired token")){
      localStorage.removeItem("token")
      window.location.href="/"

    }
    throw error;
  }
}

export const postApi = async<T>(uri: string, data: object): Promise<T> => {
    try {
      const response: AxiosResponse<T> = await axios.post(baseUrl + uri, data, getConfig());
      return response.data;
    } catch (error:any) {
      if(error?.response?.data?.message.includes("expired token")){
        localStorage.removeItem("token")
        window.location.href="/"
  
      }
      throw error;
    }
  };

  export const patchApi = async<T>(uri: string, data: object): Promise<T> => {
    try {
      const response: AxiosResponse<T> = await axios.patch(baseUrl + uri, data, getConfig());
      return response.data;
    } catch (error:any) {
      if(error?.response?.data?.message.includes("expired token")){
        localStorage.removeItem("token")
        window.location.href="/"
  
      }
      throw error;
    }
  };

  export const deleteApi = async<T>(uri:string): Promise<T> => {
    try {
      const response: AxiosResponse<T> = await axios.delete(baseUrl + uri,getConfig());
      return response.data;
    } catch (error:any) {
      if(error?.response?.data?.message.includes("expired token")){
        localStorage.removeItem("token")
        window.location.href="/"
  
      }
      throw error;
    }
  }

  export const postApiFiles = async<T>(uri: string, data: object): Promise<T> => {
    try {
      const response: AxiosResponse<T> = await axios.post(baseUrl + uri, data, getConfigFormData());
      return response.data;
    } catch (error:any) {
      if(error?.response?.data?.message.includes("expired token")){
        localStorage.removeItem("token")
        window.location.href="/"
  
      }
      throw error;
    }
  };