import { Tag } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import React from 'react'
import { User } from '../../Models/User';
import { MenuItem } from '../../Models/MenuItem';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

//transalate
import { useTranslation, Trans } from 'react-i18next';


interface Props{
  users:User[]
}


const TableUsers = ({users}:Props) => {
    const { t } = useTranslation();
    const menuItems:MenuItem[] = [
        {
          label: <span>Eliminar</span>,
          key: '0',
          title: 'Eliminar Cliente',
          icon: <DeleteOutlined />,
          component: <div>Eliminar</div>,
          type: 'CONFIRM',
          items: [],
          size: 'small',
          onConfirm: () => {},
    
        },
        {
          label: <span>Editar</span>,
          title: 'Detalle Cliente',
          key: '1',
          icon: <EditOutlined />,
          component: <div>Editar</div>,
          type: 'CONTENT',
          items: [],
          size: 'small',
          onConfirm: () => {},
    
        },
      ]

    const columns: ColumnsType<User> = [
        {
            title: t('basico.nombre'),
            dataIndex: 'nombres',
            key: 'nombres',
            // responsive:["sm"]
        },    
        {
            title: t('basico.apellidos'),
            dataIndex: 'apellidos',
            key: 'apellidos',
            // responsive:["sm"]

        },
        {
          title: t('basico.usuario'),
          dataIndex: 'usuario',
          key: 'usuario',
        },
    
        {
          title: t('basico.tipo'),
          key: 'tipoEntidad',
          dataIndex: 'tipoEntidad',
          render: (_, { rol, id }) => (
            <>
              <Tag color={"geekblue"} key={id}>
                  {rol.nombre.toUpperCase()}
              </Tag>
            </>
          ),
        },

      ];

  return (
      
      <Table  scroll={{x:100}} pagination={false} columns={columns} dataSource={users} />
  )
}

export default TableUsers