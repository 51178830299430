import React, { useContext, useEffect, useState } from 'react'
import FilterSimple from '../../Components/FilterSection/FilterSimple'
import { MenuItem } from '../../Models/MenuItem'
import CreateUserComponent from '../../Components/CreateUser/CreateUserComponent'
import TableUsers from '../../Components/TableUsers/TableUsers'
import { Card, Pagination } from 'antd'
import { PromiseSesionContext } from '../../Machines/SesionMachine'
import { User } from '../../Models/User'
import SkeletonUsersTable from '../../Components/SkeletonComponents/SkeletonUsersTable'

//transalate
import { useTranslation, Trans } from 'react-i18next';


import { getUsers } from '../../Api/Subsidiary'
import { getUsers as getUsersOrganization } from '../../Api/Organization'
import { getUsers as getUsersReseller } from '../../Api/Reseller'
import { getUsers as getUsersSupplier } from '../../Api/Supplier'
import UsersFilters from '../../Components/UsersFilters/UsersFilters'

import "./Users.css"

const Users = () => {
  const userType = "admin"
  const [loading, setLoading] = useState(false);
  const {sesion, sendSesion, sendError } = useContext(PromiseSesionContext);
  const [users, setUsers] = useState<User[]>([]);
  const [usersFilter, setUsersFilter] = useState<User[]>([]);

  const { t } = useTranslation();


  const [filters, setFilters] = useState<{nombre:string, status:string}>({nombre:"", status:""}); //filters


  //paginate
  const [page, setPage] = useState<number>(1)
  const sizePages = 10

  const items=[
    {
      title: t('basico.informacion'),
    },
    {
      title: t('basico.detalle'),
    },
  ]

  const menuItem:MenuItem ={
    label: <span>Crear</span>,
    key: '0',
    title: 'Crear Cliente',
    icon: <></>,
    component: <CreateUserComponent />,
    type: 'MULTICONTENT',
    items: items,
    size: 'small',
    onConfirm: () => {getUsersByRole()},
  }

  const getUsersByRole = async () => {
    try{
      setLoading(true)
      let newUsers:User[] = []
      switch (sesion.context.rol) {
        case "Proveedor":
          newUsers = await getUsersSupplier()
          break;
        case "Reseller":
          newUsers = await getUsersReseller()
          break;
        case "Organizacion":
          newUsers = await getUsersOrganization()
          break;
        case "Sucursal":
          newUsers = await getUsers()
          break;
        default:
          break;
    }

    setUsers(newUsers)
    setUsersFilter(newUsers)
    setLoading(false)

  }
  catch(err){
    setLoading(false)
    sendError({type: "ERROR", data:{message:t('basico.errorUsuarios')}})
  }

  }

  const filterData = async (usersArray : User[]) => {
    setUsersFilter(filterByStatus(usersArray))
  }



  const filterByStatus = (usersArray : User[]) => {
    if(filters.status==="") return filterByName(usersArray)
    return filterByName(users.filter((u:User) => u.rol.nombre === filters.status))
  }

  const filterByName = (usersArray : User[]) => {
    if(filters.nombre==="") return usersArray
    return usersArray.filter((u:User) => (u.nombres + " " + u.apellidos).toUpperCase().includes(filters.nombre.toUpperCase())  )
  }

  useEffect(() => {
    getUsersByRole();
  }, []);

  useEffect(() => {
    if(users.length>0){
      filterData(users)
    }
  }, [filters]);
  
  return (
    <div>
        <Card title={t('basico.gestionUsuarios')} style={{height:"calc(100vh - 2rem)", borderRadius:"0 8px 8px 0"}} bodyStyle={{padding:0, height:"90%", boxSizing:"border-box"}} extra={<FilterSimple menuItem={menuItem}/>}>

          <SkeletonUsersTable loading={loading}>
            <div className='Layout-Card-Paginate'>
              <UsersFilters filters={filters} setFilters={setFilters} />

            <div className='users-table'>
              <TableUsers users={usersFilter?.slice((page - 1) * sizePages, page * sizePages)}></TableUsers>
            </div>

            <div className='centerPaginate'>
                {usersFilter.length>sizePages &&  <Pagination defaultCurrent={1} onChange={(newPage, newPageSize)=>{setPage(newPage)}} current={page} total={usersFilter.length} pageSize={sizePages} showSizeChanger={false} />}
            </div>

            </div>
          </SkeletonUsersTable>
        </Card>

    </div>
  )
}

export default Users


