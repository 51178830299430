import React from 'react'


import { MenuItem } from '../../Models/MenuItem'
import { Menu, Modal } from 'antd'

interface Props{
    menuItem:MenuItem
    open:boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>

}

const ModalMenuItem = ({menuItem, open, setOpen}:Props) => {

    const closeModal= ()=>{
        setOpen(false)
    }

  const componentChild = React.cloneElement(menuItem.component, {closeModal:closeModal}) 

  return (
    <>
        {
        <Modal open={open} onCancel={()=>{setOpen(false)}} title={menuItem.title} footer={false} afterClose={()=>{menuItem.onConfirm()}} destroyOnClose >
            {componentChild}
        </Modal>
        }

    </>
  )
}

export default ModalMenuItem