import React, { useContext, useEffect, useState } from 'react'

//transalate
import { useTranslation, Trans } from 'react-i18next';

//styles
import "./Setting.css"
import { Card, Menu, MenuProps } from 'antd'
import { AppstoreOutlined, MailOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import Users from '../Users/Users';
import Notification from '../Notifications/Notification';
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import { BsPersonGear, BsPhone, BsPhoneVibrate } from "react-icons/bs";
import { BsPeople } from "react-icons/bs";
import Spiner from '../../Components/Spiner/Spiner';
import Profile from '../../Components/Profile/Profile';
import NotificationMovil from '../NotificationMovil/NotificationMovil';
type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }



const Setting = () => {
  const { t } = useTranslation();
  const [key, setKey] = useState<string>("1")
  const [validate, setValidate] = useState<boolean>(false)

  const items: MenuProps['items'] = [
    getItem(t('basico.perfil'), '1', <BsPersonGear size={18} />),
    { type: 'divider' },

    // getItem('Notificaciones', '2', <MailOutlined />),
    // { type: 'divider' },

    getItem(t('basico.usuarios'), '3', <BsPeople size={18} />),
    { type: 'divider' },
  ];

  const [itemsMenu, setItemsMenu] = useState<MenuProps['items']>(items)
  const {sesion, sendSesion, sendError } = useContext(PromiseSesionContext);



    const onClick: MenuProps['onClick'] = (e) => {
        setKey(e.key)
      };

  useEffect(() => {
    if(sesion.context.rol==="Sucursal"){
      let newItems =itemsMenu


      if(!newItems?.find((x)=> x?.key==="2")){
      newItems?.push(getItem(t('basico.notificaciones'), '2', <MailOutlined />),
      { type: 'divider' })

      newItems?.push(getItem(t('basico.notificaciones'), '4', <BsPhone />),
      { type: 'divider' })

      setItemsMenu(newItems)
    
      }
      setValidate(true)
    }
    else{
      setValidate(true)

    }

  }, []);

  useEffect(() => {
  }, [itemsMenu]);

  return (
    <Card style={{height:"calc(100vh - 2rem)" }} bodyStyle={{padding:0, height:"100%" }}  >
        <Spiner loading={!validate}>
        <div className='Settings-body'>
            <Menu
            onClick={onClick}

            style={{ width: 256, height:"100%", borderRadius:"8px 0 0 8px" }}
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="inline"
            items={itemsMenu}
            />

            <div className='Setting-Content'>
              {
                key==="1" && <Profile/>
              }
              {
                key==="2" && <Notification />
              }

              {
                key==="3" && <Users />
              }

              {
                key==="4" && <NotificationMovil />
              }
            </div>
        </div>
        </Spiner>
    </Card>
  )
}

export default Setting
