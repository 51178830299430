import React, { useContext } from 'react'
import { EditOutlined, FileAddOutlined, StopOutlined} from '@ant-design/icons'
import { Sensor } from '../../Models/Sensor'
import Table, { ColumnsType } from 'antd/es/table'
import { MenuItem } from '../../Models/MenuItem'
import Menu from '../Menu/Menu'
import { Button, Tag, Tooltip } from 'antd'
import { sensorColors, taskColors } from '../../Functions/colors'
import { sensorStatus, taskStatus } from '../../Functions/status'
import BatteryIndicator from '../BatteryIndicator/BatteryIndicator'
import StopTaskComponent from '../StopTask/StopTaskComponent'
import ReportView from '../../Reportes/ReportView'
import { PromiseSesionContext } from '../../Machines/SesionMachine'
import { BsChevronRight } from 'react-icons/bs'

//transalate
import { useTranslation, Trans } from 'react-i18next';

interface Props {
  sensors: Sensor[]
  refresh: () => void
  selectSensor: (serial:string) => void
  selectedSerial:string

}

const TableDetailAsset = ({sensors, refresh, selectSensor, selectedSerial}:Props) => {
  const { sesion } = useContext(PromiseSesionContext);
  const { t } = useTranslation();
  const menuItems:MenuItem[] = [
    // {
    //   label: <span>Generar reporte</span>,
    //   key: '0',
    //   title: 'Generar reporte',
    //   icon: <DeleteOutlined />,
    //   component: <div>Eliminar</div>,
    //   type: 'CONFIRM',
    //   items: [],
    //   size: 'small',
    //   onConfirm: () => {},

    // },
    {
      label: <span>Parar</span>,
      title: 'Detener sensor',
      key: '1',
      icon: <StopOutlined />,
      component: <div>Parar</div>,
      type: 'CONTENT',
      items: [],
      size: 'small',
      onConfirm: () => {refresh()},

    },
  ]

const columns: ColumnsType<Sensor> = [
    {
      title: t('basico.nombre'),
      dataIndex: 'nombre',
      key: 'nombre',
      render: (_, {serial_number, numero_de_serie, nombre}) =>{ 
        return <><Tooltip placement="top" title={nombre ? (serial_number || numero_de_serie) : "-"}><Button type='text' style={{color:(selectedSerial==serial_number || selectedSerial==numero_de_serie) ? "var(--primary)" : undefined}} onClick={()=>{selectSensor(serial_number || numero_de_serie)}}>{nombre||(serial_number || numero_de_serie) || "-"}</Button></Tooltip></>},
    },
    // {
    //   title: 'Serial',
    //   dataIndex: 'numero_de_serie',
    //   key: 'numero_de_serie',
    //   render: (_, {serial_number, numero_de_serie}) =>{ 
    //     return <><Button type='text' style={{color:(selectedSerial==serial_number || selectedSerial==numero_de_serie) ? "var(--primary)" : undefined}} onClick={()=>{selectSensor(serial_number || numero_de_serie)}}>{serial_number || numero_de_serie}</Button></>},
    // },
    {
      title: t('basico.bateria'),
      dataIndex: 'battery_level',
      key: 'battery_level',
      render: (_, {SensorsWithZebra, battery_level}) =>{ 
        return <><BatteryIndicator battery={SensorsWithZebra?.battery_level || battery_level}></BatteryIndicator></>},
    },
    {
      title: t('basico.estatus'),
      key: 'status',
      render: (_, {SensorsWithZebra, status}) =>{ 
        const newStatus = SensorsWithZebra?.status || status
        let color = sensorColors(newStatus);
        let texto = sensorStatus(newStatus) ;

        return <Tag color={color} > {texto}</Tag>},
        
  },  

  {
    title: t('basico.acciones'),
    key: 'action',
    render: (_, record) => {
        const menuItems:MenuItem[] = [
            {
              label: <span>{t('basico.reporteSensor')}</span>,
              key: '0',
              title: t('basico.reporteSensor'),
              icon: <FileAddOutlined />,
              component: <ReportView serialSensor={record?.serial_number} idTask={record?.most_recent?.task_id} />,
              type: 'CONTENT',
              items: [],
              size: "small",
              onConfirm: () => {},
        
            },
            
          ]

          menuItems.push(
            {
              label: <span>{t('sensor.detenerSensor')}</span>,
              title: (t('sensor.detenerSensor') +' ' +record.serial_number),
              key: '1',
              icon: <EditOutlined />,
              component: <StopTaskComponent idTask={record?.most_recent?.task_id} />,
              type: 'CONTENT',
              items: [],
              size: 'small',
              onConfirm: () => {refresh()},
        
            }
          )
        
      return <Button type='text'> <Menu noLocation menuItems={menuItems}></Menu></Button>
    },
  },

  {
    title: t('basico.detalle'),
    key: 'action',
    render: (_, {serial_number, numero_de_serie}) => {

        
      return <Button type='text' style={{color:(selectedSerial==serial_number || selectedSerial==numero_de_serie) ? "var(--primary)" : undefined}} onClick={()=>{selectSensor(serial_number || numero_de_serie)}} ><BsChevronRight /></Button>
    },
  },
  ];


  
  return (
    <Table scroll={{x:100}} columns={columns} dataSource={sensors} pagination={false} />
  )
}

export default TableDetailAsset