import React, { useContext, useEffect, useState } from 'react'
import SingleCardInformation from '../../Components/SingleCardInformation/SingleCardInformation'
import ElementCard from '../../Components/ElementCard/ElementCard'
import { Card } from 'antd'

//transalate
import { useTranslation, Trans } from 'react-i18next';

//style
import './ResellerDetail.css'
import SensorFilters from '../../Components/SensorFilters/SensorFilters'
import { getResellerDetail } from '../../Api/Supplier'
import { useParams } from 'react-router-dom'
import { PromiseSesionContext } from '../../Machines/SesionMachine'
import { Organization } from '../../Models/Organization'
import { Sensor } from '../../Models/Sensor'
import ElementCardOrganization from '../../Components/ElementCard/ElementCardOrganization'
import SkeletonSensorBasicTable from '../../Components/SkeletonComponents/SkeletonSensorBasicTable'
import TableSensorBasic from '../../Components/TableSensor/TableSensorBasic'
import { createSimpleCardInfoElements } from '../../Functions/transform'
import { Reseller } from '../../Models/Reseller'
import NoData from '../../Components/NoData/NoData'
import Spiner from '../../Components/Spiner/Spiner'

interface Elements {
  title: string;
  content: string;

}

const ResellerDetail = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);


  const [reseller, setReseller] = useState<Reseller>();

  const [organization, setOrganization] = useState<Organization[]>([]);
  const [sensors, setSensors] = useState<Sensor[]>([]);
  const [cardInfoElements, setCardInfoElements] = useState<Elements[]>([]); 

  const { t } = useTranslation();

  const [filters, setFilters] = useState<{status:string, nombre:string}>({status:"", nombre:""}); //filters
  const [sensorsFilters, setSensorsFilters] = useState<Sensor[]>([]);

  const {error, sendError} = useContext(PromiseSesionContext);

  const getData = async () => {
    try{
    setLoading(true)
    const res = await getResellerDetail(params.id || 0)

    if(res) {
      setLoading(false)

      setOrganization(res.organizacion)

      setSensors(res.sensors)
      setSensorsFilters(res.sensors)


      setReseller(res)
      setCardInfoElements(createSimpleCardInfoElements("asignado", res.sensors, t('basico.sensoresAsignados'), t('basico.sensoresNoAsignados')))
    }
    }
    catch(err){
      sendError({type: "ERROR", data:{message:t('basico.errorRegistros')}})
      setLoading(false)
    }

  }

  const asignedSensor = (sensor:Sensor):string => {
    if(sensor.asignado!==null) return t('basico.asignado')
    return t('basico.sinAsignar')
  }




  useEffect(() => {
    getData();
  }, []);


  const filterBySerial = ()=>{
    if(filters.nombre!=""){
      setSensorsFilters(filterByStatus(sensors).filter((x:Sensor) => x.numero_de_serie.includes(filters.nombre)))
    }
    else{
      setSensorsFilters(filterByStatus(sensors))
    }
  }

  const filterByStatus = (sensorArray:Sensor[]):Sensor[] =>{
    if(filters.status=="Asignados"){

      return sensorArray.filter((sensor:Sensor) => sensor.asignado !== null )
    }
    else if(filters.status=="Sin asignar"){

      return sensorArray.filter((sensor:Sensor) => sensor.asignado === null)
    }
    else{
      return sensors
    }
  }


  useEffect(() => {
    if(sensors.length>0){
      filterBySerial()
    }
  }, [filters]);
    

  let styleCard ={
    width: "49%",
    height: "100%",
  }

  return (
    <div>
        <div className='ResellerDetail-HeaderSection' >

          <Card title={reseller?.nombre || t('basico.detalle')} className='ResellerDetail-info' bodyStyle={{padding:"20px"}}  >
            <span>{reseller?.descripcion}</span>
          </Card>


          <div className='ResellerDetail-cards'>
            <div className='listSingleCardInformation' style={{height:"100%"}}>
              {!loading &&
                cardInfoElements.map((element, index) => {
                  return <SingleCardInformation  style={styleCard} title={element.title} value={element.content} />
                } )
              }

              {loading && (
                <>
                  <SingleCardInformation  style={styleCard} title={t('basico.sensoresAsignados')} value={""} loading />
                  <SingleCardInformation  style={styleCard} title={t('basico.sensoresNoAsignados')} value={""} loading />
                </>
              )}
            </div>
          </div>


        </div>


        <div className='ResellerDetail-bottom'>
            <Card title={t('basico.organizaciones')} className='ResellerDetail-organization'  bodyStyle={{padding:"12px", height:"90%", overflow:"hidden"}}  >
                {/* <ElementCard/> */}
                <Spiner loading={loading}>
                  <NoData data={organization} text={t('basico.NoOrganizaciones')}>

                  <div className='OrganizationsPage-reseller-list scrollbar-component'>        
                    {organization.map((organization, index) => {
                      return (
                        <ElementCardOrganization notShowMenu elementClass='Card-middle-Width' organization={organization} key={index} />
                      )
                    })}
                  </div>
                  </NoData>
                </Spiner>

            </Card>

            <Card title={t('basico.sensores')} className='ResellerDetail-sensors'  bodyStyle={{padding:0, height:"90%", overflow:"hidden"}}  >
                <SensorFilters filters={filters} setFilters={setFilters} />

                <SkeletonSensorBasicTable loading={loading} ><div className='ResellerDetail-sensors-Table scrollbar-component'> <TableSensorBasic asignedFuction={asignedSensor} sensors={sensorsFilters} rol={"Reseller"} /> </div> </SkeletonSensorBasicTable>
            </Card>

        </div>

    </div>
  )
}

export default ResellerDetail