import React, { useContext } from 'react'
import { TagOutlined } from '@ant-design/icons'
import { Sensor } from '../../Models/Sensor'
import Table, { ColumnsType } from 'antd/es/table'
import { MenuItem } from '../../Models/MenuItem'
import { Tag } from 'antd'
import { sensorColors } from '../../Functions/colors'
import { sensorStatus} from '../../Functions/status'
import BatteryIndicator from '../BatteryIndicator/BatteryIndicator'
import { BsFillPinMapFill, BsPencilSquare } from 'react-icons/bs'
import ModalButton from '../ModalButton/ModalButton'
import EditNameSensor from '../EditNameSensor/EditNameSensor'
import { PromiseSesionContext } from '../../Machines/SesionMachine'
import RemoveSensor from '../RemoveSensor/RemoveSensor'
import { BsXLg } from "react-icons/bs";
import MapApp from '../MapApp/MapApp'

//transalate
import { useTranslation, Trans } from 'react-i18next';

interface Props {
  sensors: Sensor[]
  getData: () => Promise<void>
}

const TableSensor = ({sensors, getData}:Props) => {
  const { t } = useTranslation();
  const {sesion, sendSesion } = useContext(PromiseSesionContext);

  const menuItems:MenuItem[] = [
    {
      label: <span>Remover</span>,
      title: 'Remover sensor',
      key: '1',
      icon: <TagOutlined />,
      component: <div>Sensores</div>,
      type: 'CONTENT',
      items: [],
      size: 'small',
      onConfirm: () => {},

    },
  ]

  const getOwner = ():"reseller" | "organizacion" | "sucursal"=> {
    switch (sesion.context.rol) {
        case "Proveedor":
            return "reseller"
        case "Reseller":
          return "organizacion"
        case "Organizacion":
          return "sucursal"
        case "Sucursal":
          return "sucursal"
        default:
          return "reseller"
    }
}
  

const columns: ColumnsType<Sensor> = [
  {
    title: t('basico.nombre'),
    dataIndex: 'numero_de_serie',
    key: 'numero_de_serie',
    render: (_, {serial_number, numero_de_serie, nombre}) =>{ 
      let menuItem:MenuItem =   {
        label: <span>Sensores</span>,
        title: t('sensor.cambiarNombre'),
        key: '1',
        icon: <TagOutlined />,
        component: <EditNameSensor serial={serial_number || numero_de_serie}/>,
        type: 'CONTENT',
        items: [],
        size: 'small',
        onConfirm: () => {},
  
      }
      return  <ModalButton menuItem={menuItem} text={nombre || "N / A"}  icon={<BsPencilSquare />}></ModalButton>
    },
  },
  {
    title: t('basico.asignado'),
    key: 'asignado',
    render: (_, record) =>{ 
      const owner = record[getOwner()]
      return <>{owner ? (owner.nombre||t('basico.sinAsignar')) :  t('basico.sinAsignar')}</>},
  },
    {
      title: t('basico.serial'),
      dataIndex: 'numero_de_serie',
      key: 'numero_de_serie',
      render: (_, {serial_number, numero_de_serie}) =>{ 
        return <>{serial_number || numero_de_serie}</>},
    },
    {
      title: t('basico.bateria'),
      dataIndex: 'battery_level',
      key: 'battery_level',
      render: (_, {SensorsWithZebra, battery_level}) =>{ 
        return <><BatteryIndicator battery={SensorsWithZebra?.battery_level || battery_level}></BatteryIndicator></>},
    },
    {
      title: t('basico.estatus'),
      key: 'status',
      
      render: (_, {SensorsWithZebra, status}) =>{ 
        const newStatus = SensorsWithZebra?.status || status
        let color = sensorColors(newStatus);
        let texto = sensorStatus(newStatus) ;

        return <Tag color={color} > {texto}</Tag>},},  

        {
          title: t('basico.ubicacion'),
          key: 'action',
          
          render: (_, {numero_de_serie, sucursal, latitud, longitud}) =>{ 
            // if(!sucursal) return <></>
            if(!latitud && !longitud) return


            let location = {lat: latitud, lng: longitud}
            let menuItem:MenuItem =   {
              label: <span>Ubicacion del sensor</span>,
              title: t('sensor.ubicacion'),
              key: '1',
              icon: <TagOutlined />,
              component: <div style={{width:"100%", height:"300px"}}> <MapApp sites={[]} location={location} findAction={()=>{}} /></div>,
              type: 'CONTENT',
              items: [],
              size: 'small',
              onConfirm: () => {},
        
            }
    
            return <ModalButton menuItem={menuItem} text={""}  icon={<BsFillPinMapFill />}></ModalButton>},},  

        {
          title: 'Remover',
          key: 'action',
          
          render: (_, {numero_de_serie, sucursal}) =>{ 
            if(!sucursal) return <></>
            
            let menuItem:MenuItem =   {
              label: <span>Remover sensor</span>,
              title: t('basico.removerSensor'),
              key: '1',
              icon: <TagOutlined />,
              component: <RemoveSensor serialSensor={numero_de_serie} idSubsidiary={sucursal.id}  />,
              type: 'CONTENT',
              items: [],
              size: 'small',
              onConfirm: () => {getData()},
        
            }
    
            return <ModalButton menuItem={menuItem} text={""}  icon={<BsXLg />}></ModalButton>},},  

  


  ];


  
  return (
    <Table scroll={{x:100}} columns={columns} dataSource={sensors} pagination={false} />
  )
}

export default TableSensor