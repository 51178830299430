import { ResponseContent } from '../Models/ResponseContent';
import { getApi, patchApi, postApi } from './Api';

export const login = async (usuario:string, contrasena:string) => {
    try{
        return await postApi<ResponseContent>('thermotrack/login', {usuario, contrasena})
    }
    catch(error){
        throw error;
    }
}

export const authRegister = async (usuario:string, token:string) => {
    try{
        return await getApi<ResponseContent>(`thermotrack/user/mail/${usuario}/code/${token}`)
    }
    catch(error){
        throw error;
    }
}

export const newUserRegister = async (usuario:string, body:object ) => {
    try{
        return await patchApi<ResponseContent>(`thermotrack/user/${usuario}/updateUser`, body)
    }
    catch(error){
        throw error;
    }
}

