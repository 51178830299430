import { FieldType } from "../Components/CreateSite/CreateSiteComponent";
import { Asset } from "../Models/Asset";
import { DataGraph } from "../Models/DataGraph";
import { Historical } from "../Models/Historical";
import { ResponseContent } from "../Models/ResponseContent";
import { Sensor } from "../Models/Sensor";
import { Site } from "../Models/Site";
import { User } from "../Models/User";
import { getApi, getApiBlob, patchApi, postApi } from "./Api";


const generateKey = async <T>(objectArray: Promise<Sensor[]>): Promise<Sensor[]> => {
    // Código de la función
    let response = await objectArray;
    for (let index = 0; index < response.length; index++) {
        response[index].key = (index+1).toString()
    }
    return response;
  };

export const getSensors = async () => {
    try{
        return await generateKey( getApi<Sensor[]>('thermotrack/organization/sensors'))
    }
    catch(error){
        throw error;
    }
}

export const postSite = async (createSubsidiaryDTO:FieldType, sensors:Sensor[], correo:string) => {
    try{
        return await postApi<ResponseContent>('thermotrack/organization/addSubsidiaryAndSensors', {createSubsidiaryDTO, sensors, correo})

    }
    catch(error){
        throw error;
    }
}

export const postStopTask = async (idSubsidiary:number | string, idTask:number | string, obj:object ) => {
    try{
        return await postApi<ResponseContent>(`thermotrack/organization/${idSubsidiary}/${idTask}/stopTask`, {})

    }
    catch(error){
        throw error;
    }
}


export const getAvailableSensors = async () => {
    try{
        let sensors = await getApi<Sensor[]>('thermotrack/organization/sensors/unassigned')
        for (let index = 0; index < sensors.length; index++) {
            sensors[index].key = (index+1).toString()
            
        }
        return await sensors
    }
    catch(error){
        throw error;
    }
}

export const getSites = async () => {
    try{
        return await getApi<Site[]>('thermotrack/organization/subsidiary')
    }
    catch(error){
        throw error;
    }
}

export const postAddUser = async (obj: object) => {
    try{
        return await postApi<ResponseContent>(`thermotrack/organization/addUsers`, obj)
    }
    catch(error){
        throw error;
    }
}

export const postAddSensors = async (id:number, sensors:Sensor[]) => {
    try{
        return await postApi<ResponseContent>(`thermotrack/organization/${id}/addSensors`, {sensors})

    }
    catch(error){
        throw error;
    }
}

export const postAddAssetToSubsidiary = async (idSubsidiary:number | string, obj:object) => {
    try{
        return await postApi<ResponseContent>(`thermotrack/organization/${idSubsidiary}/addAsset`, obj)

    }
    catch(error){
        throw error;
    }
}

export const postNewAsignSensorByOrganization = async (idAsset:string, idSensor:string, objeto: any, idSubsidiary:number | string) => {
    try{
        return await postApi<ResponseContent>(`thermotrack/organization/${idSubsidiary}/${idAsset}/asset/${idSensor}/assignSensor`, objeto)
    }
    catch(error){
        throw error;
    }
}



export const getUsers= async () => {
    try{
        return await getApi<User[]>('thermotrack/organization/users')
    }
    catch(error){
        throw error;
    }
}

export const getUpdateLocation = async () => {
    try{
        return await getApi<ResponseContent>(`idsense/sensor/sensors/validateLocation`)
    }
    catch(error){
        throw error;
    }
}

export const getAssetsDetail= async (id:number | string) => {
    try{
        return await getApi<Asset[]>('thermotrack/organization/subsidiary/'+id+'/assets')
    }
    catch(error){
        throw error;
    }
}

export const getAssetsSensorDetail= async (id:number | string) => {
    try{
        return await getApi<DataGraph[]>('thermotrack/organization/assets/'+id+'/sensors')
    }
    catch(error){
        throw error;
    }
}

export const getAssetsDetailViewData= async (idSubsidiary:number | string, idAsset:number | string) => {
    try{
        return await getApi<Asset>(`thermotrack/organization/subsidiary/${idSubsidiary}/${idAsset}/assetsDetail`)
    }
    catch(error){
        throw error;
    }
}

export const getAssetsDetailSensors= async (idSubsidiary:number | string, idAsset:number | string) => {
    try{
        return await getApi<any[]>( `thermotrack/organization/subsidiary/${idSubsidiary}/assets/${idAsset}/sensors`)
    }
    catch(error){
        throw error;
    }
}


export const getSubsidiaryUnassigned= async (idSubsidiary:number | string) => {
    try{
        return await getApi<any[]>( `thermotrack/organization/${idSubsidiary}/sensors/unassigned`)
    }
    catch(error){
        throw error;
    }
}

export const getReportDetail= async (idTask:number | string, idSubsidiary:number | string) => {
    try{
        return await getApi<any[]>( `thermotrack/organization/subsidiary/${idSubsidiary}/sensor/${idTask}/report`)
    }
    catch(error){
        throw error;
    }
}

export const getReportDetailByDate= async (idTask:number | string, idSubsidiary:number | string, from:string, to:string ) => {
    try{
        // return await getApi<any[]>( `thermotrack/organization/subsidiary/${idSubsidiary}/sensor/${idTask}/report`)
        return await getApi<any[]>( `thermotrack/organization/subsidiary/${idSubsidiary}/sensor/${idTask}/reportByDate?startDate=${from}&endDate=${to}`)

    }
    catch(error){
        throw error;
    }
}

export const patchSensor= async (idSensor:string, body:object) => {
    try{
        return await patchApi<any>(`thermotrack/organization/${idSensor}/updateSensor`, body)
    }
    catch(error){
        throw error;
    }
}

export const removeSensor= async (serialSensor:string, subsidiaryID:number) => {
    try{
        return await patchApi<ResponseContent>(`thermotrack/organization/${serialSensor}/${subsidiaryID}/removeSensorInSubsidiary`, {})
    }
    catch(error){
        throw error;
    }
}

export const getHistorical = async (id:string, idSucursal:string) => {
    try{
        return await getApi<Historical[]>(`thermotrack/organization/${idSucursal}/asset/${id}/historical`)
    }
    catch(error){
        throw error;
    }
}

export const getReportDownload= async (idTask:number | string, idSubsidiary:number | string, from:string, to:string) => {
    try{
        return await getApiBlob<any>( `thermotrack/subsidiary/sensor/taskId/${idTask}/reportByDate?startDate=${from}&endDate=${to}`)
    }
    catch(error){
        throw error;
    }
}

export const patchAsset= async (idAsset:number, idSubsidiary:number, body:object) => {
    try{
        return await patchApi<any>(`thermotrack/organization/${idSubsidiary}/${idAsset}/updateSubsidiaryAsset`, body)
    }
    catch(error){
        throw error;
    }
}

export const patchSubsidiary= async (idSubsidiary:number, body:object) => {
    try{
        return await patchApi<any>(`thermotrack/organization/${idSubsidiary}/updateSubsidiary`, body)
    }
    catch(error){
        throw error;
    }
}

export const deleteAsset= async (id:number, idSubsidiary:number) => {
    try{
        return await patchApi<any>(`thermotrack/organization/${idSubsidiary}/${id}/deleteSubsidiaryAsset`, {})
    }
    catch(error){
        throw error;
    }
}

