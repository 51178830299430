import React from 'react'

//transalate
import { useTranslation, Trans } from 'react-i18next';

//styles
import './AssetDetailCard.css'
import ButtonIcon from '../ButtonIcon/ButtonIcon'

//iconos 
import { BsThermometerSnow, BsThermometerSun,BsGraphDownArrow, BsGraphUpArrow, BsSearch, BsExclamationTriangleFill } from "react-icons/bs";


interface Props{
  high?:number
  low?:number
  interval:number
  linterval:number
  hinterval:number
  detalle:string
  titulo:string

}

const AssetDetailCard = ({high, low, interval, hinterval, linterval, detalle, titulo}:Props) => {
  const { t } = useTranslation();
  return (
    <div className='AssetDetailCard'>

      <div className='AssetDetailCard-left'>
        {<img src='/image/nevera.png' />}

      </div>

        <div className='AssetDetailCard-right'>
            {/* <span className='AssetDetailCard-rightElementEnd'><b>{titulo}</b></span> */}
            {/* <span className='AssetDetailCard-rightElementEnd'>{detalle}</span> */}

            <span className='AssetDetailCard-rightElement'> {(high || high==0) && <ButtonIcon icon={<BsThermometerSun size={18} />} text={high+" C°"} />}  <ButtonIcon icon={<BsExclamationTriangleFill size={18} />} text={`${hinterval} ${hinterval===1 ? ` ${t('basico.minuto')}` : ` ${t('basico.minutos')}`} ${t('activo.maximoTemp')}`} />  </span>
            <span className='AssetDetailCard-rightElement'>{(low || low==0) && <ButtonIcon icon={<BsThermometerSnow size={18} />} text={low+" C°"} />}  <ButtonIcon icon={<BsExclamationTriangleFill size={18} />} text={`${linterval} ${linterval===1 ? ` ${t('basico.minuto')}` : `${t('basico.minutos')}`} ${t('activo.minimoTemp')}`} /> </span>
            <span className='AssetDetailCard-rightElement'>  <ButtonIcon icon={<BsSearch size={18} />} text={`${t('activo.unaMedicion')}${interval===1 ? " " : ` ${interval} `}${interval===1 ? ` ${t('basico.minuto')}` : ` ${t('basico.minutos')}`}`} /> </span>




        </div>

    </div>
  )
}

export default AssetDetailCard