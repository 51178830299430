import { BankOutlined, BookOutlined, ControlOutlined, EnvironmentOutlined, FileOutlined, HddOutlined, HomeOutlined, IdcardOutlined, KeyOutlined, SettingOutlined, TagOutlined, UserOutlined } from "@ant-design/icons";
import { Route } from "../Models/Route";


export const supplierRoutes: Route[] = [
    {path: "/", icon: <ControlOutlined />, navbarShow: true, regex:null},
    // {path: "/Resellers", icon: <IdcardOutlined /> , navbarShow: true, regex:null},
    {path: "/Requests", icon: <FileOutlined /> , navbarShow: true, regex:null},
    {path: "/Reseller/:id", icon: <IdcardOutlined /> , navbarShow: false, regex:/^\/Reseller\/\d+$/},
    // {path: "/users", icon: <UserOutlined />, navbarShow: true, regex:null},
    {path: "/Sensor", icon: <TagOutlined />, navbarShow: true, regex:null},
    {path: "/Apikeys", icon: <KeyOutlined />, navbarShow: true, regex:null},
    {path: "/Login", icon: <></> , navbarShow: false, regex:null},
    {path: "/settings", icon: <SettingOutlined />, navbarShow: true, regex:null},


]

export const resellerRoutes: Route[] = [
    {path: "/", icon: <ControlOutlined />, navbarShow: true, regex:null},
    // {path: "/Organizations", icon: <BankOutlined />, navbarShow: true, regex:null},
    // {path: "/Sensor", icon: <TagOutlined />, navbarShow: true, regex:null},
    {path: "/Apikeys", icon: <KeyOutlined />, navbarShow: true, regex:null},
    {path: "/Organization/:id", icon: <IdcardOutlined /> , navbarShow: false, regex:/^\/Organization\/\d+$/},
    // {path: "/users", icon: <UserOutlined />, navbarShow: true, regex:null},
    {path: "/Login", icon: <></>, navbarShow: false, regex:null},
    {path: "/settings", icon: <SettingOutlined />, navbarShow: true, regex:null},

]

export const organizationRoutes: Route[] = [
    {path: "/", icon: <HomeOutlined />, navbarShow: true, regex:null},
    {path: "/Sites", icon: <EnvironmentOutlined />, navbarShow: true, regex:null},
    {path: "/Sensor", icon: <TagOutlined />, navbarShow: true, regex:null},
    // {path: "/users", icon: <UserOutlined />, navbarShow: true, regex:null},
    {path: "/Login", icon: <></>, navbarShow: false, regex:null},
    {path: "/test", icon: <></>, navbarShow: false, regex:null},
    {path: "/Asset/:id", icon: <IdcardOutlined /> , navbarShow: false, regex:/^\/Asset\/\d+$/},
    {path: "/Asset/:idAsset/:idSucursal", icon: <IdcardOutlined /> , navbarShow: false, regex:/^\/Asset\/[^\/]+\/[^\/]+$/g},
    {path: "/Assets/:id", icon: <IdcardOutlined /> , navbarShow: false, regex:/^\/Assets\/\d+$/},
    {path: "/Historic/:id/:idSucursal", icon: <IdcardOutlined /> , navbarShow: false, regex:/^\/Historic\/[^\/]+\/[^\/]+$/g},
    {path: "/settings", icon: <SettingOutlined />, navbarShow: true, regex:null},





]

export const siteRoutes: Route[] = [
    {path: "/", icon: <HomeOutlined />, navbarShow: true, regex:null},
    // {path: "/Assets", icon: <HddOutlined />, navbarShow: true, regex:null},
    {path: "/Asset/:id", icon: <IdcardOutlined /> , navbarShow: false, regex:/^\/Asset\/\d+$/},
    // {path: "/Tasks", icon: <BookOutlined />, navbarShow: true, regex:null},
    {path: "/Sensor", icon: <TagOutlined />, navbarShow: true, regex:null},
    // {path: "/users", icon: <UserOutlined />, navbarShow: true, regex:null},
    {path: "/settings", icon: <SettingOutlined />, navbarShow: true, regex:null},
    {path: "/Login", icon: <></>, navbarShow: false, regex:null},
    {path: "/Historic/:id", icon: <></>, navbarShow: false, regex:/^\/Historic\/[^\/]+$/},



]

export const nonRoutes: Route[] = [
    // {path: "/", icon: <HomeOutlined />, navbarShow: true, regex:null},
    {path: "/Login", icon: <></>, navbarShow: false, regex:null},
    {path: "/verify/:user/:code", icon: <IdcardOutlined /> , navbarShow: false, regex:/^\/verify\/[^\/]+\/[^\/]+$/g},


]