import React, { useContext, useEffect } from 'react'

//Components
import LoginForm from '../../Components/LoginForm/LoginForm'
import { useNavigate } from 'react-router-dom';
import { PromiseSesionContext } from '../../Machines/SesionMachine';

const Login = () => {
  const navigate = useNavigate();

  const {sesion, sendSesion} = useContext(PromiseSesionContext);

  useEffect(() => {
    if (sesion.value==="authorized") {
      navigate("/");
    }



  }, [sesion.value]);
  return (
    <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", height:"100%"}}>
        <LoginForm></LoginForm>
    </div>
  )
}

export default Login