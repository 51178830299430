import React from 'react'

//styles
import './SingleCardInformation.css'
import Spiner from '../Spiner/Spiner'
import { BsChevronRight, BsEye } from 'react-icons/bs'
import { Button } from 'antd'

interface Props {
    title: string,
    value: string| number,
    style?: React.CSSProperties
    loading?: boolean
    haveDetails?:boolean
    action?: () => void
    elementClass?:string
}

const SingleCardInformation = ({title, value, style, loading, haveDetails, action, elementClass}:Props) => {
    
  return (
    <div className={'SingeCardInformation ' + (elementClass && elementClass)} style={style}>
        <span className='SingeCardInformation-title'>{title}</span>

        <Spiner minHeight={1} loading ={loading}>
          <span className='SingeCardInformation-value'>{value}</span>
        </Spiner>

        {/* {(haveDetails && !loading) &&
        <div className='SingeCardInformation-details'>
          <Button type='text' onClick={()=>{action!()}}>
          <BsChevronRight />
          </Button>
        </div>} */}
    </div>
  )
}

export default SingleCardInformation