import React, { useEffect, useState } from 'react'

import { getAvailableSensors, postAddSensors } from '../../Api/Organization';
import { useNavigate } from 'react-router-dom';

//transalate
import { useTranslation, Trans } from 'react-i18next';

import ElementCard from './ElementCard'
import { MenuItem } from '../../Models/MenuItem'
import { PlusOutlined } from '@ant-design/icons'
import EditSensorsComponent from '../EditSensors/EditSensorsComponent'
import { Site } from '../../Models/Site';
import { BsForward, BsSliders } from 'react-icons/bs';
import UpdateSubsidiary from '../UpdateSubsidiary/UpdateSubsidiary';

interface Props{
    site: Site, 
    propsStyles?: React.CSSProperties 
    action?: (id: string | number) => void
    notShowMenu?: boolean,
    refresh?: () => void,
    showAlarms?:boolean
    elementClass?:string
}

const ElementCardReseller = ({site, propsStyles, action, notShowMenu, refresh, showAlarms, elementClass}:Props) => {
    const [alarms, setAlarms] = useState<number>(0)
    const navigate = useNavigate();
    const { t } = useTranslation();


    const generateAlarms = () => {
      let count =0
      for(let t of site.sensor){
        if(t.tarea.length>0){
          count =  count + (t.tarea[0].alarm_count>0 ? 1 : 0)
        }
      }
      setAlarms(count)
    }

    useEffect(() => {
      if(showAlarms){
        generateAlarms()
      }
    }, [showAlarms]);

    const [menuItems] = useState<MenuItem[]>([{
        label: <span>{t('basico.asignarSensores')}</span>,
        title: t('basico.asignarSensores'),
        key: '1',
        icon: <PlusOutlined />,
        component: <EditSensorsComponent type='reseller' getFunction={getAvailableSensors} id={site.id} executeFunction={postAddSensors} />,
        type: 'CONTENT',
        items: [],
        size: 'small',
        onConfirm: () => {refresh!()},
  
      },
      {
        label: <span>{t('site.editar')}</span>,
        title: t('site.editar'),
        key: '2',
        icon: <BsSliders />,
        component: <UpdateSubsidiary site={site} />,
        type: 'CONTENT',
        items: [],
        size: 'small',
        onConfirm: () => {refresh!()},
  
      },
      {
        label: <span>{t('site.activos')}</span>,
        title: t('site.activos'),
        key: '3',
        icon: <BsForward />,
        component: <></>,
        type: 'REDIRECT',
        items: [],
        size: 'small',
        onConfirm: () => {navigate(`/Assets/${site.id}`)},
  
      },]);
      
  return (
    <ElementCard elementClass={elementClass} alarms={alarms} sensors={site.sensor.length}  id={site.id} propsStyles={propsStyles} showAlarms={showAlarms} onClick={action ? action : undefined} menuItems={notShowMenu ? undefined :menuItems} title={site.nombre} description={site.descripcion} image='/image/sucursal.png' status='' dontShowStatus/>
  )
}

export default ElementCardReseller