import React, { useContext, useEffect, useState } from 'react'
import { Site } from '../../Models/Site';
import { Button, Card, Switch } from 'antd';

//transalate
import { useTranslation, Trans } from 'react-i18next';


import { PromiseSesionContext } from '../../Machines/SesionMachine';


//api
import { getAssetsDetail, getAssetsSensorDetail, getSites } from '../../Api/Organization'
import { Asset } from '../../Models/Asset';
import ElementCardAsset from '../../Components/ElementCard/ElementCardAsset';
import { Sensor } from '../../Models/Sensor';

import SubsidiaryGraph from '../../Components/SubsidiaryGraph/SubsidiaryGraph';
import Spiner from '../../Components/Spiner/Spiner';
import NoData from '../../Components/NoData/NoData';
import ButtonIcon from '../../Components/ButtonIcon/ButtonIcon';
import { TagOutlined } from '@ant-design/icons';

import { getSensors as getsensorsOrganization } from '../../Api/Organization'
import { useNavigate } from 'react-router-dom';
import TableSite from '../../Components/TableSite/TableSite';
import { BsFileSpreadsheet, BsGeoAlt } from 'react-icons/bs';
import MapApp from '../../Components/MapApp/MapApp';


const DashboardOrganizacion = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingA, setLoadingA] = useState(false);
  const [loadingS, setLoadingS] = useState(false);

  const [idAsset, setIdAsset] = useState<string | number>("")
  const [idSite, setIdSite] = useState<string | number>("")
  const [showMap, setShowMap] = useState<boolean>(false)

  const [temperature, setTemperature] = useState<{max:number | null, min:number | null}>({max:null,  min:null})



  const [sites, setSites] = useState<Site[]>([]);
  const [site, setSite] = useState<Site | null>();

  const [assets, setAssets] = useState<Asset[]>([]);
  const [asset, setAsset] = useState<Asset | null>(null);
  const [sensor, setSensor] = useState<Sensor[]>([]);
  const [SensorsFilters, setSensorsFilters] = useState<Sensor[]>([]);

  const [usedAssetSensors, setUsedAssetSensors] = useState<number>(0);
  const [sensorsInfo, setSensorsInfo] = useState<null | {asigned:number, unasigned:number}  >(null);



  const [dataGraph, setDataGraph] = useState<any[]>([]);
  const navigate = useNavigate();



  const { error, sendError } = useContext(PromiseSesionContext);


  const getData = async () => {
    try{
    setLoading(true)
    const res = await getSites()
    setSites(res)
    setLoading(false)
    }
    catch(err){
      sendError({type: "ERROR", data:{message:t('site.error')}})
      setLoading(false)
    }
  }

  useEffect(() => {
    getData();
    getSensors()
  }, []);

  const countSensors = (asset: Asset[]) => {
    let count = 0
    asset.forEach(asset => {
      count +=  (asset.tarea.length)
    })
    return count
  }

  const findAssetBySite =async (id: string | number) => {
    try{
      setUsedAssetSensors(0)
      setIdSite(id)
      setSite(sites.find(site => site.id === id) || null)

      setIdAsset("")
      setAsset(null)

      setSensor([])
      setDataGraph([])
      setLoadingA(true)
      const res = await getAssetsDetail(id)

      let x = countSensors(res)
      setUsedAssetSensors(x)

      setAssets(res)
      setLoadingA(false)
      }
      catch(err){
        sendError({type: "ERROR", data:{message:t('activo.error')}})
        setLoadingA(false)

      }
  }

  const createSensors = (asset?: Asset) =>{
    if(!asset) return []
    let tasks = asset.tarea
    let sensorsResult = []

    for (let t of tasks) {
      let sensorUnit = t.sensor
      sensorsResult.push(sensorUnit)
    }

    return sensorsResult
  }
  const getSensors = async () => {
    try{
      let newSensor = await getsensorsOrganization()
      setSensorsInfo({asigned:newSensor.filter(s => s.sucursal!==null).length, unasigned: newSensor.filter(s => s.sucursal==null).length})

    }
    catch{
      sendError({type: "ERROR", data:{message:t('sensor.errorObtener')}})

    }

  }
   const findSensorsByAsset = async (id: string | number) => {
    try{
      setIdAsset(id)
      let assetFind = assets.find(asset => asset.id === id) || null
      if(assetFind) setTemperature({max:assetFind?.tarea[0].taskDetails?.alarm_high_temp|| null,  min: assetFind?.tarea[0].taskDetails?.alarm_low_temp || null})
      setAsset(assetFind)

      // setSensor(assets.find(asset => asset.id === id)?.sensor || [])
      setSensor(createSensors(assets.find(asset => asset.id === id)))

      setLoadingS(true)
      const res = await getAssetsSensorDetail(id)
      let newRes = res

      if(res.length>0){
        newRes[0].sensorData = newRes[0].sensorData.reverse()
      }

      setDataGraph(newRes )
      setLoadingS(false)
      }
      catch(err){
        sendError({type: "ERROR", data:{message:t('activo.errorDatos')}})
        setLoadingS(false)

      }
   }

   const asignedFuction = (sensor: Sensor) => {
    return assets.find(asset => asset.id === idAsset)?.nombre || t('basico.asignado')
   }


  let styleCard ={
    width: "33%",
  }


  useEffect(() => {
  }, [showMap]);

  return (
    <div className='DashboardOrganizacion-Layout'>
      <div className='DashboardOrganizacion-TopLayout' >
        <Card title={t('site.mis')} className='dashboardOrganizacion-card-site' bodyStyle={{padding:"12px", height:"90%", overflowY:"hidden"}} extra={sensorsInfo && <div style={{display:"flex", flexDirection:"row", gap:"8px", alignItems:"center", justifyContent:"center"}}> <Switch onChange={(e)=>{ setShowMap(e)}} checkedChildren={ <BsGeoAlt />} unCheckedChildren={<BsFileSpreadsheet />} checked={showMap} /> <ButtonIcon icon={<TagOutlined />} text={t('basico.asignados')} qty={ sensorsInfo.asigned} /> <ButtonIcon icon={<TagOutlined />} text={t('basico.sinAsignar')} qty={ sensorsInfo.unasigned} />  </div>}  >
            <Spiner loading={loading} >
              <NoData data={sites} text={t('site.sinDatos')}>
              <div className='ResellerPage-list scrollbar-component' style={{height:"100%", width:"100%", overflowY:"scroll"}}>

                {/* {sites.map((site, index) => {
                  return (
                    <ElementCardSites showAlarms notShowMenu propsStyles={{width:"48%"}} action={findAssetBySite} site={site} />
                  )
                })} */}

                {!showMap ? <TableSite sites={sites} getFunction={getData} findAction={findAssetBySite} /> : <MapApp sites={sites} findAction={findAssetBySite} />}
              </div>

              </NoData>
            </Spiner>


          {/* <div className='centerPaginate'>
            <Pagination defaultCurrent={1} current={1} total={sites.length} pageSize={12} />
          </div> */}
        </Card>




      </div>

      <div className='DashboardOrganizacion-TopLayout'>
        {/* <Card title={"Sensores del activo"} style={{width:"49.5%", height:"calc(50vh - 1rem)"}}  bodyStyle={{padding:"12px"}}  >

            <Spiner loading={loadingS} >

              <NoData data={dataGraph} text={idAsset ? "Sin datos en el sensor" :'Seleccione un activo para ver los datos'}>
                <SkeletonSensorBasicTable loading={loadingS} > <TableSensorBasic sensors={sensor} rol={"Reseller"} asignedFuction={asignedFuction} /> </SkeletonSensorBasicTable>
              </NoData>
            </Spiner>

          </Card> */}

          <div className='dashboardOrganizacion-card-divided'>


          <Card title={`${t('basico.activos')} ${(idSite ? t('activo.deLaSucursal') : "")+(idSite && sites.find((x)=> x.id==idSite)?.nombre.toLowerCase())}`} className='dashboardOrganizacion-card-asset'  bodyStyle={{padding:"12px", height:"75%", overflowY:"hidden"}}  extra={<div style={{display:"flex", flexDirection:"row", gap:"8px"}}>   <ButtonIcon icon={<TagOutlined />} text={t('basico.sinAsignar')} qty={ ((site?.sensor.length|| 0) - usedAssetSensors) || 0} />  </div>} >
            <Spiner loading={loadingA} >
              <NoData data={assets} text={idSite ? t('activo.sinActivosSucursal') :t('activo.seleccioneSucursal')}>
                <div className='ResellerPage-list scrollbar-component' style={{height:"100%", overflowY:"scroll"}}>
                    {assets.map((asset, index) => {
                      return (
                        <ElementCardAsset showAlarms simpleCard notShowMenu action={findSensorsByAsset} elementClass='Card-middle-Width'  asset={asset} />
                      )
                    })}

                </div>
              </NoData>
            </Spiner>

          </Card>
          </div>

          <Card  title={`${t('basico.grafico')} ${(idAsset ? t('activo.delActivo') : "")+(idAsset && assets.find((x)=> x.id==idAsset)?.nombre.toLowerCase())}`} className='dashboardOrganizacion-card-graph'  bodyStyle={{padding:"12px", height:"80%"}}  extra={idAsset && <Button type='primary' onClick={()=>{ navigate("/Asset/"+idAsset.toString()+'/'+idSite.toString()) }}>{t('basico.verDetalle')}</Button>} >
            <Spiner loading={loadingS} >
              <NoData data={dataGraph} text={idAsset ? t('activo.sinDatosEnSensor') :t('activo.activoParaDatos')}>
                <SubsidiaryGraph maxRef={temperature.max || undefined} minRef={temperature.min || undefined} enterData={dataGraph} />
              </NoData>
            </Spiner>


          </Card>
      </div>

    </div>
  )
}

export default DashboardOrganizacion

