import React, { useContext } from 'react'
import CreateUser from './CreateUser';
import FinishedSuccess from '../FinishedSuccess/FinishedSuccess';
import { PromiseSesionContext } from '../../Machines/SesionMachine';

//transalate
import { useTranslation, Trans } from 'react-i18next';

//api
import { postAddUser } from '../../Api/Supplier'
import { postAddUser as postAddUserReseller } from '../../Api/Reseller'
import { postAddUser as postAddUserOrganization } from '../../Api/Organization'
import { postAddUser as postAddUserSubsidiary } from '../../Api/Subsidiary'


import Spiner from '../Spiner/Spiner';
import { ResponseContent } from '../../Models/ResponseContent';

export type FieldType = {
  usuario?: string;
  telefono?: string;
  contrasena?: string;
  nombres?: string;
  apellidos?: string;
  correo?: string;
};

interface Props{
    step?:number
    setStep?: React.Dispatch<React.SetStateAction<number>>
    next?: () => void,
    prev?: () => void,
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>

  }

const CreateUserComponent = ({step, setStep, next, prev, setOpen}:Props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [user, setUser] = React.useState<FieldType>({});
    const [messageResponse, setMessageResponse] = React.useState<string>("");

    const {sesion, sendSesion, sendError } = useContext(PromiseSesionContext);

    const onFinishClient = async (values: any) => {
        try{
          setLoading(true)
          setUser(values)
          values.telefono = values.prefix + values.telefono.toString()
          delete values.prefix
          let res:ResponseContent
          switch (sesion.context.rol) {
            case "Proveedor":
                res = await postAddUser(values)
                break;
            case "Reseller":
                res = await postAddUserReseller(values)
                break;
            case "Organizacion":
                res = await postAddUserOrganization(values)
                break;
            case "Sucursal":
                res = await postAddUserSubsidiary(values)
                break;
            default:
              break;
          }

          setLoading(false)
          next!()
          
        }
        catch(err){
          setLoading(false)
          sendError({type: "ERROR", data:{message:t('usuario.errorCrear')}})
        }
        
      };
    
  return (
    <div >
      <Spiner loading={loading}>
        {step===0 ? <CreateUser initialValues={user} onFinishMaster={onFinishClient} ></CreateUser>
        : <FinishedSuccess titulo={t('usuario.creadoExitoso')} onFunction={()=>{setOpen!(false)}} />} 
        {/* : step===1 ? <AssignUser onFinishMaster={onFinishClientUser} />  */}
      </Spiner>
    </div>
  )
}

export default CreateUserComponent