

export const ENACTIVO = {
    activo: {
        titulo:"Asset management",

        informacion:"Asset information",
        caracteristicas:"Asset characteristics",
        temperaturaActivo:"Asset Temperature Range",
        categoria:"Asset category",
        editar:"Edit asset",
        tareaRangoTemperatura:"Temperature range",
        rangoError:"must indicate the temperature",
        minutosTemperaturaBaja:"Minutes at low temperature",
        minutosTemperaturaAlta:"Minutes at high temperature",
        errorMinutos:"You must indicate the minutes",
        intervaloMinutos:"Interval in minutes",
        intervaloSeconds:"Interval in seconds",
        detalleTarea:"Task detail",
        nombreTarea:"Name of the task",
        descripcionTarea:"Task description",
        tempBaja:"Low temperature",
        tempAlta:"High temperature",
        duracionBaja:"Duration in temp. low",
        duracionAlta:"Duration in temp. high",
        intervalo:"Reading interval",
        detalleTemperatura:"Temperature detail",
        temperaturaBaja:"Lowest temperature",
        temperaturaPromedio:"Average temperature",
        ultimaLectura:"Last reading",
        totalRegistros:"Total records",
        temperaturaAlta:"Highest temperature",
        primeraLectura:"First reading",
        conteoAlarmas:"Alarm count",
        detalleAlarma:"Alarm detail",
        deLaSucursal:" form the subsidiary ",
        sinActivosSucursal:"Subsidiary without assets",
        seleccioneSucursal:"Select a subsidiary to view its assets",
        delActivo:" from asset ",
        sinDatosEnSensor:"No data on sensor",
        activoParaDatos:"Select an asset to view data",
        mediaTemperatura:"Average temperature",
        unaMedicion:"One measurement each",
        maximoTemp:"maximum at high temperature",
        minimoTemp:"maximum at low temperature",

        error:"Error getting assets",
        errorDatos:"Error getting asset data",
        errorTemperatura:"Must indicate temperature range",
        errorCategoria:"Select a category",
        errorCarga:"Error loading assets",
        errorCategorias:"Error when obtaining categories",
        errorCrear:"Error creating asset",
        errorActualizar:"Error updating asset",
        errorReporte:"Error generating report",
        errorGrafico:"Error generating subsidiary data",

        sinActivos:"There are no assets",

        placeHolderNombre:"Asset name",
        placeHolderDescripcion:"Asset Description",
        placeHolderModo:"Fixed or mobile asset",
        placeHolderCategoria:"Energy category of the asset",

        

    

        placeHolderNombreTarea:"Name of the homework",
        placeHolderDescripcionTarea:"Task description",

        mensajeEnergeticoActivo:"The data in this form is related to the asset, it is not for temperature measurement.",
        mensajeReporte:"The date must be within 30 days.",
        reporteTemperatura:"Temperature report",
        eliminar:"Delete asset",
        mensajeEliminar:"Are you sure you want to remove this asset, the sensor will stop if it is active?",
        eliminado:"Asset deleted",
        mensajeEliminar2:"the asset cannot be deleted, has an uninitialized sensor",
        errorSegundos:"You must indicate the seconds",
        errorStart:"Indicate the start mode",
        modoInicio:"Start mode",

        placeHolderInicio:"Select start mode",
        opcionBoton:"Start by button",
        opcionAuto:"Automatic start"

      }
}

export const ESACTIVO = {
    activo: {
        titulo:"Gestión de activos",

        informacion:"Información del activo",
        caracteristicas:"Características del activo",
        temperaturaActivo:"Rango de temperatura del activo",
        categoria:"Categoria del activo",
        editar:"Editar activo",
        tareaRangoTemperatura:"Rango de temperatura",
        rangoError:"debe indicar la temperatura",
        minutosTemperaturaBaja:"Minutos en temperatura baja",
        minutosTemperaturaAlta:"Minutos en temperatura alta",
        errorMinutos:"Debe indicar los minutos",
        intervaloMinutos:"Intervalo en minutos",
        intervaloSeconds:"Intervalo en segundos",
        detalleTarea:"Detalle de la tarea",
        nombreTarea:"Nombre de la tarea",
        descripcionTarea:"Descripción de la tarea",
        tempBaja:"Temperatura baja",
        tempAlta:"Temperatura alta",
        duracionBaja:"Duración en temp. baja",
        duracionAlta:"Duración en temp. alta",
        intervalo:"Intervalo de lectura",
        detalleTemperatura:"Detalle de temperatura",
        temperaturaBaja:"Temperatura más baja",
        temperaturaPromedio:"Temperatura promedio",
        ultimaLectura:"Ultima lectura",
        totalRegistros:"Total de registros",
        temperaturaAlta:"Temperatura más alta",
        primeraLectura:"Primera lectura",
        conteoAlarmas:"Conteo de alarmas",
        detalleAlarma:"Detalle de alarma",
        deLaSucursal:" de la sucursal ",
        sinActivosSucursal:"Sucursal sin activos",
        seleccioneSucursal:"Seleccione una sucursal para ver sus activos",
        delActivo:" del activo ",
        sinDatosEnSensor:"Sin datos en el sensor",
        activoParaDatos:"Selecciona un activo para ver los datos",
        mediaTemperatura:"Media de temperatura",
        unaMedicion:"Una medición cada",
        maximoTemp:"Máximo en temperatura alta",
        minimoTemp:"Máximo en temperatura baja",

        error:"Error al obtener los activos",
        errorDatos:"Error al obtener los datos del activo",
        errorTemperatura:"Debe indicar el rango de temperatura",
        errorCategoria:"Seleccione una categoría",
        errorCarga:"Error al cargar los activos",
        errorCategorias:"Error al obtener las categorías",
        errorCrear:"Error al crear el activo",
        errorActualizar:"Error al actualizar el activo",
        errorReporte:"Error al generar reporte",
        errorGrafico:"Error al generar los datos de la sucursal",

        sinActivos:"No hay activos",

        placeHolderNombre:"Nombre del activo",
        placeHolderDescripcion:"Descripción del activo",
        placeHolderModo:"Activo fijo o móvil",
        placeHolderCategoria:"Categoria energética del activo",

        

    

        placeHolderNombreTarea:"Nombre de la tarea",
        placeHolderDescripcionTarea:"Descripción de la tarea",

        mensajeEnergeticoActivo:"Los datos de este formulario son relacionados al activo, no son para medición de temperatura",
        mensajeReporte:"La fecha debe estar en un rango de 30 días",
        reporteTemperatura:"Reporte de temperatura",
        eliminar:"Eliminar activo",
        mensajeEliminar:"¿Seguro que desea eliminar este activo, el sensor se detendra si se encuentra activo?",
        eliminado:"Activo eliminado.",
        mensajeEliminar2:"El activo no puede ser eliminado, tiene un sensor sin iniciar",
        errorSegundos:"Debe indicar los segundos",
        errorStart:"Indique el modo de inicio",
        modoInicio:"Modo de inicio",

        placeHolderInicio:"Seleccione modo de inicio",
        opcionBoton:"Inicio por botón",
        opcionAuto:"Inicio automático"


      }
}